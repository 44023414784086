import { Select, Spin } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import debounce from 'lodash/debounce';
import React from 'react';
import { hot } from 'react-hot-loader';

import { SearchedCompanyDto } from '../../server/modules/company/dto/searched-company.dto';
import { findCompany } from '../tools/api';

const Option = Select.Option;

interface Props {
  onSelect: (company: SearchedCompanyDto) => void;
}

interface State {
  loading: boolean;
  companies: SearchedCompanyDto[];
}

class CompanySearchBar extends React.Component<Props, State> {
  fetchId: number = 0;

  state: State = {
    loading: false,
    companies: [],
  };

  componentDidMount() {
    this.fetch = debounce(this.fetch, 350);
  }

  fetch = async (searched: string) => {
    this.fetchId += 1;
    const fetchId = this.fetchId;
    this.setState({ loading: true, companies: [] });
    const companies = await findCompany(searched);
    if (fetchId !== this.fetchId) {
      return;
    }
    this.setState({ loading: false, companies });
  };

  handleSelect = (gojobId: string) => {
    this.props.onSelect(this.state.companies.find(company => company.gojobId === gojobId));
  };

  render() {
    const { loading, companies } = this.state;
    return (
      <FormItem>
        <Select
          showSearch
          placeholder="Nom de la société sur Gojob"
          notFoundContent={loading ? <Spin size="small" /> : null}
          filterOption={false}
          onSearch={this.fetch}
          onSelect={this.handleSelect}
        >
          {companies.map(company => (
            <Option key={company.gojobId}>{company.name}</Option>
          ))}
        </Select>
      </FormItem>
    );
  }
}

export default hot(module)(CompanySearchBar);
