import React from 'react';
import { hot } from 'react-hot-loader';

import { CompanyDto } from '../../server/common/dto/company.dto';
import { ExtractScopedCompanyDto } from '../../server/modules/extract/dto/extract-scoped-company.dto';
import { getCompanyURL } from '../tools/admin';

interface Props {
  company: CompanyDto | ExtractScopedCompanyDto;
}

const GojobCompanyLink = ({ company }: Props) => (
  <a href={getCompanyURL(company.gojobId)} target="_blank">
    {company.gojobName}
  </a>
);

export default hot(module)(GojobCompanyLink);
