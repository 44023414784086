import { Icon, Layout, Menu } from 'antd';
import React from 'react';
import { hot } from 'react-hot-loader';
import { BrowserRouter as Router, Link, Redirect, Route, Switch } from 'react-router-dom';

import { hasToken } from '../tools/authentication';
import CompanyLinked from './CompanyLinked';
import CompanyList from './CompanyList';
import ExtractList from './ExtractList';
import HelpSummary from './HelpSummary';
import Login from './Login';

interface State {
  connected: boolean;
}

class PageLayout extends React.Component<{}, State> {
  state: State = {
    connected: hasToken(),
  };

  refreshConnectionStatus = () => {
    this.setState({ connected: hasToken() });
  };

  renderSider = () => (
    <Layout.Sider width={300}>
      <Menu theme="dark" mode="inline">
        <Menu.Item key="1">
          <Link to="/companies">
            <Icon type="home" />
            <span className="nav-text">Clients</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="2">
          <Link to="/files">
            <Icon type="upload" />
            <span className="nav-text">Extracts</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="3">
          <Link to="/help">
            <Icon type="read" />
            <span className="nav-text">Aide</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="4">
          <a href="https://www.clicandstaff.fr/login" target="_blank">
            <Icon type="link" />
            <span className="nav-text">C&S</span>
          </a>
        </Menu.Item>
      </Menu>
    </Layout.Sider>
  );

  render() {
    return (
      <Router>
        <Layout style={{ minHeight: '100vh' }}>
          {this.state.connected ? this.renderSider() : null}
          <Layout.Content style={{ padding: 20 }}>
            <Switch>
              <Route exact path="/companies" component={CompanyList} />
              <Route path="/companies/:id/missions" component={CompanyLinked} />
              <Route exact path="/files" component={ExtractList} />
              <Route exact path="/help" component={HelpSummary} />

              {this.state.connected ? <Redirect to="/companies" /> : null}
            </Switch>
            <Switch>
              <Route exact path="/login" component={() => <Login onChange={this.refreshConnectionStatus} />} />
              {this.state.connected ? null : <Redirect to="/login" />}
            </Switch>
          </Layout.Content>
        </Layout>
      </Router>
    );
  }
}

export default hot(module)(PageLayout);
