import fetch from 'cross-fetch';

const url = process.env.ADMIN_API;

const TokenKey = 'token';

export async function login(login: string, password: string): Promise<boolean> {
  try {
    const response = await fetch(`${url}/user/signin`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        login,
        password,
      }),
    });
    const json = await response.json();
    if (json.token) {
      localStorage.setItem(TokenKey, json.token);
      return true;
    }
  } catch (err) {}
  return false;
}

export function logout(): void {
  localStorage.removeItem(TokenKey);
  window.location.replace('/login');
}

export function getToken(): string | undefined {
  return localStorage.getItem(TokenKey);
}

export function hasToken(): boolean {
  return !!localStorage.getItem(TokenKey);
}
