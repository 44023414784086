import React from 'react';
import { hot } from 'react-hot-loader';

import { MissionLightDto } from '../../server/common/dto/mission-light.dto';
import { ExtractScopedMissionDto } from '../../server/modules/extract/dto/extract-scoped-mission.dto';
import { getMissionLink } from '../tools/admin';

interface Props {
  mission?: MissionLightDto | ExtractScopedMissionDto;
  missionId?: string;
  label: string;
}

const GojobMissionLink = ({ mission, missionId, label }: Props) => (
  <a href={getMissionLink(missionId || (mission ? mission.gojobId : ''))} target="_blank">
    {label}
  </a>
);

export default hot(module)(GojobMissionLink);
