import { Button, Modal, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';

import { WorkerDto } from '../../server/common/dto/worker.dto';
import { GenderTypeEnum } from '../../server/common/enums/gender-type.enum';
import { getWorker, refreshWorker } from '../tools/api';
import Loading from './Loading';

const WorkerModal = ({ gojobId, onClose }: { gojobId: string; onClose: () => void }) => {
  const [worker, setWorker] = useState<WorkerDto>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      const worker = await getWorker(gojobId);
      setWorker(worker);
      setLoading(false);
    };
    load();
    return undefined;
  }, []);

  async function refresh() {
    setLoading(true);
    const worker = await refreshWorker(gojobId);
    setWorker(worker);
    setLoading(false);
  }

  interface DataType {
    property: string;
    value: string;
  }

  const columns: Array<ColumnProps<DataType>> = [
    {
      title: 'Propriété',
      key: 'prop',
      dataIndex: 'property',
    },
    {
      title: 'Valeur',
      key: 'value',
      dataIndex: 'value',
    },
  ];

  const dataSource: DataType[] = [];

  if (worker) {
    dataSource.push(
      { property: 'Nom', value: worker.lastname },
      { property: 'Prénom', value: worker.firstname },
      { property: 'Civilité', value: worker.gender ? (worker.gender === GenderTypeEnum.MALE ? 'Homme' : 'Femme') : '' },
      { property: 'Nationalité', value: worker.nationality },
      { property: 'Adresse', value: worker.address },
      { property: 'Adresse (2)', value: worker.address2 },
      { property: 'Code Postal', value: worker.zip },
      { property: 'Ville', value: worker.city ? worker.city.name : '' },
      { property: 'Matricule', value: worker.number },
      { property: 'Téléphone', value: worker.phone },
      { property: 'Date de naissance', value: worker.birth ? worker.birth.date : '' },
      { property: 'Ville de naissance', value: worker.birth && worker.birth.city ? worker.birth.city.name : '' },
      { property: 'Pays de naissance', value: worker.birth && worker.birth.country ? worker.birth.country.name : '' },
    );
  }

  return (
    <Modal
      width={1200}
      visible={true}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={refresh} loading={loading} style={{ marginRight: 30 }}>
          Rafraîchir les données de l'utilisateur
        </Button>,
        <Button key="submit" type="primary" onClick={onClose} loading={loading}>
          Ok
        </Button>,
      ]}
    >
      {!worker ? <Loading /> : <Table columns={columns} dataSource={dataSource} size="small" style={{ marginTop: 30 }} pagination={false} />}
    </Modal>
  );
};

export default hot(module)(WorkerModal);
