import { Badge, Button, Icon, Modal, notification, Popconfirm, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader';

import { ExtractLogDto } from '../../server/modules/extract/dto/extract-log.dto';
import { ExtractScopeItemDto } from '../../server/modules/extract/dto/extract-scope-item.dto';
import { ExtractDto } from '../../server/modules/extract/dto/extract.dto';
import { RemainingDataDto } from '../../server/modules/extract/dto/remaining-data.dto';
import {
  deleteExtract,
  generateNewExtract,
  getLatestExtracts,
  hasRemainingData,
  refreshInvoices,
  refreshMissions,
  tagExtractAsSent,
} from '../tools/api';
import Extract from './Extract';
import GojobCompanyLink from './GojobCompanyLink';
import GojobInvoiceLink from './GojobInvoiceLink';
import GojobMissionLink from './GojobMissionLink';
import GojobUserLink from './GojobUserLink';
import Loading from './Loading';

type ShowExtractItemsButtonProps = {
  extract: ExtractDto;
  items: ExtractScopeItemDto[];
};

const ShowExtractItemsButton: React.FC<ShowExtractItemsButtonProps> = ({ extract, items }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Button type="default" onClick={() => setShow(true)}>
        Afficher
      </Button>
      <Modal
        destroyOnClose
        footer={null}
        title={`Elements de l'extract du ${moment(extract.created).format('DD/MM/YY')}`}
        visible={show}
        onCancel={() => setShow(false)}
        width="80%"
        style={{ top: 30, marginBottom: 30 }}
      >
        {items.map((item: ExtractScopeItemDto, idx: number) => (
          <span className="scope" key={`extract-${extract.id}-scope-${idx}`}>
            {item.invoice ? (
              <>
                <GojobInvoiceLink invoice={item.invoice} />
                <span> pour </span>
              </>
            ) : null}
            {item.worker ? (
              <>
                <GojobUserLink user={item.worker} />
                <span> chez </span>
              </>
            ) : null}
            <GojobCompanyLink company={item.company} />
            {item.mission ? (
              <>
                <span> du </span>
                <GojobMissionLink
                  mission={item.mission}
                  label={`${moment(item.mission.from).format('DD/MM/YY')} au ${moment(item.mission.to).format('DD/MM/YY')}`}
                />
              </>
            ) : null}
            <br />
          </span>
        ))}
      </Modal>
    </>
  );
};

interface State {
  loading: boolean;
  generating: boolean;
  refreshingMissions: boolean;
  refreshingInvoices: boolean;
  deletingExtract: boolean;
  extracts: ExtractDto[];
  newExtracts: ExtractDto[];
  remainingData: RemainingDataDto;
}

class ExtractList extends React.Component<{}, State> {
  private timer: NodeJS.Timeout;

  state: State = {
    loading: true,
    generating: false,
    refreshingMissions: false,
    refreshingInvoices: false,
    deletingExtract: false,
    extracts: [],
    newExtracts: null,
    remainingData: null,
  };

  async componentDidMount() {
    const { extracts } = await getLatestExtracts();
    await this.refreshExtractStatus();
    this.setState({ loading: false, extracts });
    this.timer = setInterval(() => this.refreshExtractStatus(), 2000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  tagAsSent = async (extract: ExtractDto) => {
    const { extract: updated, error } = await tagExtractAsSent(extract.id);

    if (!updated) {
      notification.error({
        message: 'Erreur lors de la mise à jour',
        description: error.message || 'Erreur inconnue',
      });
      return;
    }
    const extracts = this.state.extracts.slice();
    const index = extracts.indexOf(extract);
    if (index < 0) {
      throw new Error('extract not found in list');
    }
    extracts.splice(index, 1, updated);
    this.setState({ extracts });
    notification.success({
      message: 'Export mis à jour',
      description: "L'export a bien été noté comme enregistré",
    });
  };

  refreshExtractStatus = async () => {
    const remainingData = await hasRemainingData();
    this.setState({ remainingData });
  };

  generate = async () => {
    this.setState({ generating: true, newExtracts: null });
    const { extracts } = await generateNewExtract();

    if (extracts.length) {
      notification.success({
        message: 'Export réalisé',
        description: 'Le nouvel export a été réalisé avec succès',
      });
    } else {
      notification.info({
        message: 'Aucun nouvel export',
        description: "La base de données est à jour, aucun fichier n'a été généré",
      });
    }
    this.setState({ generating: false, newExtracts: extracts, extracts: [...extracts, ...this.state.extracts] });
  };

  handleUpdateResult = (result: { updated?: boolean; deleted?: boolean; error?: Error }) => {
    if (result.error) {
      notification.error({
        message: 'Erreur lors de la mise à jour',
        description: result.error.message || 'Erreur inconnue',
      });
    } else if (result.updated) {
      notification.success({
        message: 'Mise à jour effectuée',
        description: 'Des données ont été mises à jour, vous pouvez à présent générer un nouvel export',
      });
    } else if (result.deleted) {
      notification.success({
        message: 'Suppression effectuée',
        description: "L'extract a bien été supprimé",
      });
    } else {
      notification.info({
        message: 'Aucune mise à jour disponible',
        description: "La base de données est à jour, aucune modification n'a été détectée",
      });
    }
  };

  refreshMissions = async () => {
    this.setState({ refreshingMissions: true });
    this.handleUpdateResult(await refreshMissions());
    this.setState({ refreshingMissions: false });
  };

  refreshInvoices = async () => {
    this.setState({ refreshingInvoices: true });
    this.handleUpdateResult(await refreshInvoices());
    this.setState({ refreshingInvoices: false });
  };

  confirmDelete = () => {
    Modal.confirm({
      width: 800,
      title: "Suppression d'un extract",
      content: (
        <span>
          Attention, vous allez supprimer le dernier extract. <br />
          <strong>Cette action est définitive, Etes vous sûr(e) ?</strong>
        </span>
      ),
      okText: 'Oui, je le supprime et je risque la colère des dieux !!',
      cancelText: 'Non, je me dégonfle !',
      autoFocusButton: 'cancel',
      onOk: this.doubleConfirmDelete,
    });
  };

  doubleConfirmDelete = () => {
    Modal.confirm({
      width: 800,
      title: 'Derniere chance !!',
      content: (
        <span>
          Etes vous vraiment, vraiment sûr(e) de vouloir <strong>supprimer cet export ?</strong>
        </span>
      ),
      okText: 'oui',
      cancelText: "Non, je flippe et je m'arrête la",
      autoFocusButton: 'cancel',
      onOk: this.deleteLastExtract,
    });
  };

  deleteLastExtract = async () => {
    const { extracts } = this.state;
    const last = extracts
      .slice()
      .filter(extract => !extract.deleted)
      .sort((a, b) => (a.created < b.created ? -1 : 1))
      .pop();
    if (!last) {
      return notification.error({
        message: 'Erreur de suppression',
        description: 'Extract ID non trouvé',
      });
    }
    this.setState({ deletingExtract: true });
    try {
      const deleted = await deleteExtract(last.id);
      this.handleUpdateResult({ deleted: true });
      this.setState({
        deletingExtract: false,
        extracts: extracts.map(extract => (extract === last ? deleted : extract)),
      });
    } catch (error) {
      this.handleUpdateResult({ error });
    }
  };

  renderActionColumn = (extract: ExtractDto) => [
    <Extract key="extact" extract={extract} />,
    <Popconfirm
      key="sent"
      placement="leftTop"
      title="Etes vous sûr de vouloir marquer ce fichier comme envoyé chez clic and staff ?"
      onConfirm={() => this.tagAsSent(extract)}
      okText="Oui"
      cancelText="Non"
      disabled={!!extract.sent}
    >
      <br />
      <br />
      <Button type="primary" disabled={!!(extract.sent || extract.deleted)}>
        Marquer comme envoyé
      </Button>
    </Popconfirm>,
  ];

  render() {
    if (this.state.loading) {
      return <Loading />;
    }

    const columns: Array<ColumnProps<ExtractDto>> = [
      { key: 'creation', title: 'Date de création', width: 130, render: (extract: ExtractDto) => moment(extract.created).format('DD/MM/YYYY HH:mm') },
      {
        key: 'sent',
        title: "Date d'envoi",
        width: 130,
        render: (extract: ExtractDto) => (extract.sent ? moment(extract.sent).format('DD/MM/YYYY HH:mm') : 'Non envoyé'),
      },
      {
        key: 'scope',
        title: 'Périmètre',
        width: 620,
        render: (extract: ExtractDto) => {
          const items: ExtractScopeItemDto[] = extract.scope || [];

          return (
            <div>
              <div>
                L'extract contient {items.length} élément{items.length > 1 ? 's' : ''}
              </div>
              <ShowExtractItemsButton extract={extract} items={items} />
            </div>
          );
        },
      },
      {
        key: 'logs',
        title: 'Logs',
        className: 'logs',
        render: (extract: ExtractDto) =>
          extract.logs.map((log: ExtractLogDto, idx: number) => (
            <span className="log" key={`extract-${extract.id}-log-${idx}`}>
              <span>{moment(log.date).format('DD/MM/YY HH:mm:ss')}</span>
              <GojobUserLink user={log.author} />
              <span>{log.message}</span>
              <br />
            </span>
          )),
      },
      {
        key: 'actions',
        title: 'Actions',
        align: 'center',
        render: (extract: ExtractDto) => this.renderActionColumn(extract),
      },
    ];

    const { generating, refreshingInvoices, refreshingMissions, deletingExtract } = this.state;

    return (
      <div style={{ position: 'relative' }}>
        <div className="extract-buttons">
          <Button
            type="primary"
            className="btn-red"
            onClick={this.confirmDelete}
            loading={deletingExtract}
            disabled={generating || !this.state.extracts.length}
          >
            Supprimer le dernier extract
          </Button>
          <Button
            type="primary"
            className="btn-volcano"
            onClick={this.refreshInvoices}
            loading={refreshingInvoices}
            disabled={generating || refreshingMissions}
          >
            Rafraichir les factures
          </Button>
          <Button
            type="primary"
            className="btn-green"
            onClick={this.refreshMissions}
            loading={refreshingMissions}
            disabled={generating || refreshingInvoices}
          >
            Rafraichir les missions
          </Button>
          <Badge
            count={
              this.state.remainingData.contract || this.state.remainingData.invoice ? (
                <Icon type="fire" theme="twoTone" twoToneColor="#ff0000" style={{ fontSize: 18 }} />
              ) : null
            }
          >
            <Button
              type="primary"
              id="generate"
              className={this.state.remainingData.contract || this.state.remainingData.invoice ? 'hasRemainingData' : ''}
              onClick={this.generate}
              loading={generating}
              disabled={refreshingMissions || refreshingInvoices}
            >
              Générer un nouvel export
            </Button>
          </Badge>
        </div>
        <h1>Extracts</h1>
        <p style={{ textAlign: 'left', fontSize: 15, padding: '5px 0' }}>
          Si le SFTP ne fonctionne pas, envoyer les extracts à l'adresse email suivante:{' '}
          <a href="mailto:tma.clicandstaff@ajilon.fr?subject=export" target="_blank">
            tma.clicandstaff@ajilon.fr
          </a>{' '}
        </p>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={this.state.extracts}
          pagination={{ pageSize: 30 }}
          size="small"
          rowClassName={(extract: ExtractDto) => (extract.deleted ? 'deleted-extract' : '')}
        />
      </div>
    );
  }
}

export default hot(module)(ExtractList);
