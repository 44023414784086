import { Select } from 'antd';
import React from 'react';
import { hot } from 'react-hot-loader';

const Option = Select.Option;

export interface ISelectorItem {
  value: string | number;
  label?: string;
}

interface Props {
  placeholder?: string;
  selected: string | number;
  items: ISelectorItem[];
  disabled?: boolean;
  onChange: (value: string | number) => void;
  error?: string;
}

const Selector = ({ placeholder, onChange, selected, items }: Props) => (
  <Select showSearch placeholder={placeholder} onChange={onChange} value={selected} optionFilterProp="children">
    {items.map(item => (
      <Option value={item.value} key={item.value.toString()}>
        {item.label === undefined ? item.value : item.label}
      </Option>
    ))}
  </Select>
);

export default hot(module)(Selector);
