import { Checkbox, Col, Divider, Form, Input, Modal, notification, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import FormItem from 'antd/es/form/FormItem';
import React from 'react';
import { hot } from 'react-hot-loader';

import { CompanyDto } from '../../server/common/dto/company.dto';
import { MissionLightDto } from '../../server/common/dto/mission-light.dto';
import { MissionDto } from '../../server/common/dto/mission.dto';
import { IExchangeFieldOption } from '../../server/common/interfaces/exchange-field-option.interface';
import { IExchangeField, isExchangeFieldSelect, isExchangeFieldText } from '../../server/common/interfaces/exchange-field.interface';
import departments from '../../server/common/json/departments.json';
import familyStatus from '../../server/common/json/familyStatus.json';
import jobs from '../../server/common/json/jobs.json';
import reasons from '../../server/common/json/reasons.json';
import residencePermits from '../../server/common/json/residencePermits.json';
import { CompanyConfigurationDto } from '../../server/modules/company/dto/company-configuration.dto';
import { IValidationErrorObject } from '../tools/ajax';
import { getCompany, getConfiguration, getCustomJobs, getExchangeFields, getStructures, getUnlinkedMission, linkMission } from '../tools/api';
import { decodeContractNumber } from '../tools/contract.tools';
import GojobMissionLink from './GojobMissionLink';
import GojobUserLink from './GojobUserLink';
import Loading from './Loading';
import Selector, { ISelectorItem } from './Selector';

// I know...
const dependants = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map(value => ({ value }));
const strangerBirthDepartment = '99';

interface Props {
  companyId: string;
  gojobId: string;
  onClose: (mission?: MissionLightDto) => void;
}

interface State {
  loading: boolean;
  confirmLoading: boolean;
  validationErrors: IValidationErrorObject;

  company: CompanyDto;
  configuration: CompanyConfigurationDto;
  mission: MissionDto;
  structures: ISelectorItem[];
  exchangeFields: IExchangeField[];
  customJobs: IExchangeFieldOption[];

  birthCityHelper: string;

  structureId: string;
  jobId: string;
  reasonCode: string;
  stranger: boolean;
  birthDepartment: string;
  residencePermit?: {
    type: string;
    number: string;
  };
  familyStatus: string;
  dependants: number;
  exchangeValues: { [key: string]: string | number };
  workerNumber: string; // => numberByClient
}

interface IItem {
  key: string;
  label: string;
  content: any;
}

class MissionLinkForm extends React.Component<Props, State> {
  state: State = {
    loading: true,
    confirmLoading: false,
    validationErrors: {},

    company: null,
    configuration: null,
    mission: null,
    structures: [],
    exchangeFields: [],
    customJobs: [],

    birthCityHelper: '',

    structureId: undefined,
    jobId: undefined,
    reasonCode: undefined,
    stranger: false,
    birthDepartment: undefined,
    residencePermit: undefined,
    familyStatus: undefined,
    dependants: undefined,
    exchangeValues: {},
    workerNumber: undefined,
  };

  async componentDidMount() {
    const { companyId, gojobId } = this.props;

    const company = await getCompany(companyId);
    const mission = await getUnlinkedMission(gojobId);
    const configuration = await getConfiguration(companyId);
    const structures = await getStructures(companyId);
    const exchangeFields = await getExchangeFields(companyId);
    const customJobs = await getCustomJobs(companyId);

    const birthCityHelper = [
      mission.worker.birth.city ? mission.worker.birth.city.name : '',
      mission.worker.birth.country ? mission.worker.birth.country.name : '',
    ]
      .filter(s => s)
      .join(', ');

    const stranger: boolean = mission.worker.stranger || !!mission.worker.residencePermit || this.state.stranger;

    this.setState({
      loading: false,
      company,
      configuration,
      mission,
      structures: structures.map(structure => ({ label: structure.name, value: structure.id })),
      exchangeFields,
      customJobs,
      birthCityHelper,
      reasonCode: mission.reason.code || this.state.reasonCode,
      stranger,
      birthDepartment: (mission.worker.birth ? mission.worker.birth.department : '') || this.state.birthDepartment || undefined,
      residencePermit: mission.worker.residencePermit
        ? { number: mission.worker.residencePermit.number, type: mission.worker.residencePermit.type || '2' }
        : undefined,
      familyStatus: mission.worker.familyStatus || this.state.familyStatus,
      dependants: mission.worker.dependants || this.state.dependants,
      workerNumber: mission.worker.numberByClient[configuration.exchange],
    });
  }

  getModalTitle = () => {
    const { company, mission } = this.state;
    if (company && mission) {
      return (
        <span>
          {company.name} - <GojobUserLink user={mission.worker} /> {mission.worker.number}
        </span>
      );
    }
    return 'Loading...';
  };

  unsetFromValidationError(key: string): { validationErrors: IValidationErrorObject } {
    const validationErrors = { ...this.state.validationErrors };
    delete validationErrors[key];
    return { validationErrors };
  }

  buildSetter = (key: keyof State, modifier?: (value: any) => any) => {
    return (value: any) => {
      this.setState({ [key]: modifier ? modifier(value) : value, ...this.unsetFromValidationError(key) });
    };
  };

  handleStrangerChange = (event: CheckboxChangeEvent) => {
    this.setState({ stranger: event.target.checked, birthDepartment: event.target.checked ? strangerBirthDepartment : this.state.birthDepartment });
  };

  setResidencePermitType = (type: string) => {
    this.setState({ residencePermit: { ...this.state.residencePermit, type }, ...this.unsetFromValidationError('residencePermit.type') });
  };

  setWorkerNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ workerNumber: event.target.value });
  };

  setResidencePermitNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      residencePermit: { ...this.state.residencePermit, number: event.target.value },
      ...this.unsetFromValidationError('residencePermit.number'),
    });
  };

  setExchangeValue = (key: string, value: string | number) => {
    this.setState({ exchangeValues: { ...this.state.exchangeValues, [key]: value }, ...this.unsetFromValidationError(key) });
  };

  handleOk = async () => {
    this.setState({ confirmLoading: true, validationErrors: {} });
    const { mission, error } = await linkMission({
      companyId: this.props.companyId,
      gojobId: this.state.mission.gojobId,
      structureId: this.state.structureId,
      jobId: parseInt(this.state.jobId, 10),
      exchangeValues: this.state.exchangeValues,
      reasonCode: this.state.reasonCode,
      stranger: this.state.stranger,
      birthDepartment: this.state.birthDepartment,
      residencePermit: this.state.residencePermit,
      familyStatus: this.state.familyStatus,
      dependants: this.state.dependants,
      workerNumber: this.state.workerNumber,
    });
    if (!error) {
      notification.success({
        message: 'Mission liée',
        description: 'La mission a bien été liée',
      });
      this.props.onClose(mission);
    } else {
      const validationErrors = error.validationErrors || {};

      this.setState({
        confirmLoading: false,
        validationErrors,
      });

      const errorList = Object.keys(validationErrors)
        .map(prop => `${prop} : ${validationErrors[prop]}`)
        .join('\n');

      notification.error({
        message: 'Erreur lors de la mise à jour, veuillez ré-essayer',
        description: (
          <>
            {(errorList || error.message || 'Erreur inconnue').split('\n').map((line: string, index: number) => (
              <>
                <span key={`err-${index}`}>{line}</span>
                <br />
              </>
            ))}
          </>
        ),
      });
    }
  };

  handleCancel = () => {
    this.props.onClose();
  };

  getMissionlinks(): any[] {
    const missionIds: string[] = [];

    if (this.state.mission.amendment) {
      missionIds.push(this.state.mission.amendment.root.gojobId, ...this.state.mission.amendment.root.amendments.map(amendment => amendment.gojobId));
    } else if (this.state.mission.amendments) {
      missionIds.push(this.state.mission.gojobId, ...this.state.mission.amendments.map(amendment => amendment.gojobId));
    } else {
      missionIds.push(this.state.mission.gojobId);
    }

    return missionIds.map((missionId, index) => (
      <span key={`item_${index}`}>
        {index ? <span>, </span> : null}
        <GojobMissionLink label={index ? `Avenant #${index}` : 'Mission'} missionId={missionId} />
      </span>
    ));
  }

  getItems(): IItem[][] {
    const column1: IItem[] = [];
    const column2: IItem[] = [];
    const column3: IItem[] = [];

    column1.push({
      key: 'structureId',
      label: 'Structure',
      content: (
        <Selector
          placeholder="Structure"
          items={this.state.structures}
          selected={this.state.structureId}
          onChange={this.buildSetter('structureId')}
        />
      ),
    });

    column1.push({
      key: 'jobId',
      label: 'Métier',
      content: (
        <Selector
          placeholder="Métier"
          items={this.state.customJobs.length ? this.state.customJobs : jobs}
          selected={this.state.jobId}
          onChange={this.buildSetter('jobId')}
        />
      ),
    });

    column1.push({
      key: 'reasonCode',
      label: 'Raison de recours',
      content: (
        <Selector placeholder="Raison de recours" items={reasons} selected={this.state.reasonCode} onChange={this.buildSetter('reasonCode')} />
      ),
    });

    this.state.exchangeFields.forEach(field => {
      if (isExchangeFieldText(field)) {
        column2.push({
          key: field.key,
          label: field.label,
          content: (
            <Input
              type="text"
              placeholder={field.label}
              value={this.state.exchangeValues[field.key]}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setExchangeValue(field.key, event.target.value)}
            />
          ),
        });
        return;
      }

      if (isExchangeFieldSelect(field)) {
        column2.push({
          key: field.key,
          label: field.label,
          content: (
            <Selector
              placeholder={field.label}
              selected={this.state.exchangeValues[field.key]}
              items={field.values}
              onChange={(value: string | number) => this.setExchangeValue(field.key, value)}
            />
          ),
        });
        return;
      }

      throw new Error('Unknown field type');
    });

    column3.push({
      key: 'stranger',
      label: 'Etranger',
      content: (
        <Checkbox checked={this.state.stranger} onChange={this.handleStrangerChange}>
          <span>Etranger</span>
          {this.state.birthCityHelper ? (
            <span>
              <span> (Né à </span>
              <a href={`https://maps.google.com/?q=${encodeURIComponent(this.state.birthCityHelper)}`} target="_blank">
                {this.state.birthCityHelper}
              </a>
              <span>)</span>
            </span>
          ) : null}
        </Checkbox>
      ),
    });

    column3.push({
      key: 'birthDepartment',
      label: 'Département de naissance',
      content: (
        <Selector
          placeholder="Département de naissance"
          items={departments}
          selected={this.state.birthDepartment}
          disabled={this.state.stranger}
          onChange={this.buildSetter('birthDepartment')}
        />
      ),
    });

    if (this.state.residencePermit) {
      column3.push({
        key: 'residencePermit.type',
        label: 'Type de permis de résidence',
        content: (
          <Selector
            placeholder="Type de permis de résidence"
            items={residencePermits}
            selected={this.state.residencePermit.type}
            onChange={this.setResidencePermitType}
          />
        ),
      });

      column3.push({
        key: 'residencePermit.number',
        label: 'Numéro du permis de résidence',
        content: (
          <Input
            type="text"
            placeholder="Numéro du permis de résidence"
            value={this.state.residencePermit.number}
            onChange={this.setResidencePermitNumber}
          />
        ),
      });
    }

    if (this.state.mission.worker.residencePermit) {
      column3.push({
        key: '',
        label: '',
        content: (
          <div>
            {this.state.mission.worker.residencePermit.files.map((file, index) => (
              <a key={`file_${index}`} href={file.url} target="_blank" className="file">
                {file.name}
              </a>
            ))}
          </div>
        ),
      });
    }

    column3.push({
      key: 'familyStatus',
      label: 'Situation de famille',
      content: (
        <Selector
          placeholder="Situation de famille"
          items={familyStatus}
          selected={this.state.familyStatus}
          onChange={this.buildSetter('familyStatus')}
        />
      ),
    });

    column3.push({
      key: 'dependants',
      label: 'Nombre de personnes à charge',
      content: (
        <Selector
          placeholder="Nombre de personnes à charge"
          items={dependants}
          selected={this.state.dependants}
          onChange={this.buildSetter('dependants', (dependants: string) => parseInt(dependants, 10))}
        />
      ),
    });

    if (this.state.configuration.requiresWorkerNumber) {
      column3.push({
        key: 'workerNumber',
        label: 'Matricule chez le client',
        content: <Input type="text" placeholder="Matricule" value={this.state.workerNumber} onChange={this.setWorkerNumber} />,
      });
    }

    return [column1, column2, column3].filter(column => column.length);
  }

  renderItem = (item: IItem, index: number) => {
    return (
      <FormItem
        label={item.label}
        key={`form_${item.key}_${index}`}
        validateStatus={item.key && this.state.validationErrors[item.key] ? 'error' : undefined}
        help={item.key ? this.state.validationErrors[item.key] || '' : ''}
      >
        {item.content}
      </FormItem>
    );
  };

  render() {
    if (this.state.loading) {
      return <Loading />;
    }

    const columns = this.getItems();
    const links = this.getMissionlinks();

    return (
      <Modal
        width={1200}
        visible={true}
        title={this.getModalTitle()}
        confirmLoading={this.state.confirmLoading}
        okButtonProps={{ disabled: this.state.loading }}
        cancelButtonProps={{ disabled: this.state.confirmLoading }}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
      >
        <Form>
          <div className="missionHeader">
            <label>
              <span>Admin:</span>
              {links}
            </label>
            <label>
              <span>Contract: </span>
              <span>{decodeContractNumber(this.state.mission)}</span>
            </label>
            <label>
              <span>Description:</span>
              <span>{this.state.mission.desc}</span>
            </label>
            <label>
              <span>Justificatif:</span>
              <span>{this.state.mission.reason.justification}</span>
            </label>
          </div>
          <Divider />
          <Row gutter={16}>
            {columns.map((items: IItem[], index: number) => (
              <Col key={`col_${index}`} span={24 / columns.length}>
                {' '}
                {items.map((item, index) => this.renderItem(item, index))}{' '}
              </Col>
            ))}
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default hot(module)(MissionLinkForm);
