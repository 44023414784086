import { Button, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React from 'react';
import { hot } from 'react-hot-loader';

import { CompanyDto } from '../../server/common/dto/company.dto';
import { getCompanyList } from '../tools/api';
import CompanyLinkForm from './CompanyLinkForm';
import GojobCompanyLink from './GojobCompanyLink';

interface State {
  loading: boolean;
  companies: CompanyDto[];
  companyToLink: CompanyDto;
}

class CompanyList extends React.Component<{}, State> {
  state: State = {
    loading: true,
    companies: [],
    companyToLink: undefined,
  };

  async componentDidMount() {
    try {
      const companies = await getCompanyList();
      this.setState({ loading: false, companies });
    } catch (e) {
      console.log(e);
    }
  }

  handleClose = (company?: CompanyDto) => {
    if (company) {
      // replace previous company (unlinked) to the new one (linked) into the list
      const companies = this.state.companies.slice();
      const index = companies.indexOf(this.state.companyToLink);
      if (index < 0) {
        throw new Error('company not found in list');
      }
      companies.splice(index, 1, company);
      this.setState({ companyToLink: undefined, companies });
    } else {
      this.setState({ companyToLink: undefined });
    }
  };

  render() {
    const columns: Array<ColumnProps<CompanyDto>> = [
      { key: 'name', title: 'Nom du site', dataIndex: 'name' },
      { key: 'gojob', title: 'Client sur Gojob', render: (company: CompanyDto) => (company.gojobId ? <GojobCompanyLink company={company} /> : null) },
      {
        key: 'actions',
        title: 'Actions',
        render: (company: CompanyDto) => [
          <Button type="primary" disabled={!!company.gojobId} key="missions" onClick={() => this.setState({ companyToLink: company })}>
            Lier
          </Button>,
          <Button type="primary" className="btn-green" disabled={!company.gojobId} key="link" href={`/companies/${company.id}/missions`}>
            Missions
          </Button>,
        ],
      },
    ];

    return (
      <div>
        <Table rowKey="id" columns={columns} dataSource={this.state.companies} pagination={{ pageSize: 30 }} size="small" />
        {this.state.companyToLink ? <CompanyLinkForm company={this.state.companyToLink} onClose={this.handleClose} /> : null}
      </div>
    );
  }
}

export default hot(module)(CompanyList);
