import { Button, Icon, Input, notification, Table, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import latinize from 'latinize';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { hot } from 'react-hot-loader';

import { MissionLightDto } from '../../server/common/dto/mission-light.dto';
import { getMissions, unLinkMission } from '../tools/api';
import { decodeContractNumber, formatReadableDate } from '../tools/contract.tools';
import { isMissionLinked } from '../tools/mission.tools';
import GojobMissionLink from './GojobMissionLink';
import InvalidateCompanyButton from './InvalidateCompanyButton';
import Loading from './Loading';
import MissionLinkForm from './MissionLinkForm';
import RefreshMissionButton from './RefreshMissionButton';
import WorkerModal from './WorkerModal';

interface Props {
  companyId: string;
  onLinkRequest: (mission: MissionLightDto) => void;
}

interface State {
  loading: boolean;
  missions: MissionLightDto[];
  missionToLink: MissionLightDto;
  missionUnlinked: { [key: string]: boolean };

  workerIdToShow: string;
  searchText: string;
}

interface IColumItem {
  key: string;
  mission: MissionLightDto;
}

class MissionList extends React.Component<Props, State> {
  private searchInput = React.createRef<Input>();

  state: State = {
    loading: true,
    missions: [],
    missionToLink: null,
    missionUnlinked: {},
    workerIdToShow: '',
    searchText: '',
  };

  async componentDidMount() {
    const missions = await getMissions(this.props.companyId);
    this.setState({ loading: false, missions });
  }

  handleLinkClose = (mission?: MissionLightDto) => {
    // replace previous company (unlinked) to the new one (linked) into the list
    if (mission) {
      const missions = this.state.missions.slice();
      const index = missions.indexOf(this.state.missionToLink);
      if (index < 0) {
        throw new Error('mission not found in list');
      }
      missions.splice(index, 1, mission);
      this.setState({ missionToLink: null, missions });
    } else {
      this.setState({ missionToLink: null });
    }
  };

  handleUnLinkMission = async (missionToUnlink: MissionLightDto) => {
    this.setState({ missionUnlinked: { ...this.state.missionUnlinked, [missionToUnlink.gojobId]: true } });
    const promises = [];
    if (missionToUnlink.amendment && missionToUnlink.amendment.root) {
      promises.push(this.unlinkContract(missionToUnlink.amendment.root.gojobId));

      missionToUnlink.amendment.root.amendments.forEach(contrat => {
        promises.push(this.unlinkContract(contrat.gojobId));
      });
    } else {
      // This contract is already present in "amendments section"
      promises.push(this.unlinkContract(missionToUnlink.gojobId));
    }

    await Promise.all(promises);
  };

  unlinkContract = async (gojobId: string) => {
    const { mission, error } = await unLinkMission(gojobId);
    if (!error) {
      notification.success({
        message: `Contrat déliée : ${gojobId}`,
        description: `Le contrat a bien été déliée : ${gojobId}`,
      });
    } else {
      notification.error({
        message: `Erreur pour délier le contrat ${gojobId}`,
        description: (
          <>
            {(error.message || 'Erreur inconnue').split('\n').map((line: string, index: number) => (
              <>
                <span key={`err-${index}`}>{line}</span>
                <br />
              </>
            ))}
          </>
        ),
      });
    }
  };

  handleInfoClose = () => {
    this.setState({ workerIdToShow: '' });
  };

  handleSearch = (selectedKeys: any[], confirm: () => void) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters: () => void) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  getColumnSearchProps = (
    dataIndex: string,
    getValue: (record: IColumItem) => string = (record: IColumItem) => record[dataIndex as keyof IColumItem] as string,
  ) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: {
      setSelectedKeys: (selectedKeys: any[]) => void;
      selectedKeys: any[];
      confirm: () => void;
      clearFilters: () => void;
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={this.searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered: boolean) => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: string, record: IColumItem) =>
      latinize(getValue(record))
        .toLowerCase()
        .includes(latinize(value).toLowerCase()),
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => (this.searchInput.current ? this.searchInput.current.select() : null));
      }
    },
    render: (text: string | undefined, record: IColumItem) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        sanitize={latinize}
        textToHighlight={getValue(record)}
      />
    ),
  });

  linkMissionTitle(mission: MissionLightDto) {
    if (this.state.missionUnlinked[mission.gojobId]) return 'Veuillez recharger la page pour lier cette mission.';
    if (isMissionLinked(mission)) return 'Cette mission a déjà été liée.';
    return 'Lier la mission.';
  }
  render() {
    if (this.state.loading) {
      return <Loading />;
    }

    const columns: Array<ColumnProps<IColumItem>> = [
      { key: 'contract', title: 'Numéro de contrat', width: 200, align: 'center', render: (item: IColumItem) => decodeContractNumber(item.mission) },
      { key: 'empty1', width: 50, render: () => '' },
      {
        key: 'name',
        title: 'Nom / Prénom',
        width: 220,
        align: 'center',
        ...this.getColumnSearchProps('name', (item: IColumItem) => `${item.mission.worker.lastname} ${item.mission.worker.firstname}`),
      },
      { key: 'empty2', width: 150, render: () => '' },
      {
        key: 'from',
        title: 'Du',
        align: 'center',
        width: 220,
        render: (item: IColumItem) => formatReadableDate(item.mission.from),
      },
      {
        key: 'to',
        title: 'Au',
        align: 'center',
        width: 220,
        render: (item: IColumItem) => formatReadableDate(item.mission.to),
      },
      { key: 'empty3', render: () => '' },
      {
        key: 'links',
        title: 'Lien',
        align: 'center',
        width: 50,
        render: (item: IColumItem) => <GojobMissionLink key="admin" label="Admin" mission={item.mission} />,
      },
      {
        key: 'actions',
        title: 'Actions',
        align: 'center',
        className: 'action-list',
        width: 400,
        render: (item: IColumItem) => (
          <>
            <Tooltip placement="bottomRight" title={this.linkMissionTitle(item.mission)}>
              <Button
                key="link"
                type="primary"
                disabled={isMissionLinked(item.mission)}
                onClick={() => this.setState({ missionToLink: item.mission })}
              >
                Lier
              </Button>
            </Tooltip>

            <Tooltip placement="bottomRight" title="Délier la mission">
              <Button
                key="link"
                disabled={!isMissionLinked(item.mission) || this.state.missionUnlinked[item.mission.gojobId]}
                onClick={() => this.handleUnLinkMission(item.mission)}
              >
                ⚠️ Délier
              </Button>
            </Tooltip>
            <Tooltip placement="bottomRight" title="Voir les informations du gojobbeur">
              <Button
                key="info"
                type="default"
                disabled={!isMissionLinked(item.mission)}
                onClick={() => this.setState({ workerIdToShow: item.mission.worker.gojobId })}
              >
                <Icon type="user" />
              </Button>
            </Tooltip>
            <RefreshMissionButton gojobId={item.mission.gojobId} disabled={!isMissionLinked(item.mission)} />
            <InvalidateCompanyButton gojobId={item.mission.gojobId} disabled={!isMissionLinked(item.mission)} />
          </>
        ),
        filters: [
          {
            text: 'A lier',
            value: 'Y',
          },
        ],
        onFilter: (value: string, item: IColumItem) => (value ? !isMissionLinked(item.mission) : true),
      },
    ];

    const { missions, missionToLink, workerIdToShow } = this.state;
    const { companyId } = this.props;

    return (
      <div>
        <Table
          columns={columns}
          dataSource={missions.map(mission => ({
            mission,
            key: `${mission.id}-${mission.number}-${mission.worker.lastname}-${mission.worker.firstname}-${mission.from}`, // some of those data may be empty, so, we try to build a real unique key
          }))}
          pagination={{ pageSize: 30 }}
          size="small"
        />
        {missionToLink ? <MissionLinkForm companyId={companyId} gojobId={missionToLink.gojobId} onClose={this.handleLinkClose} /> : null}
        {workerIdToShow ? <WorkerModal gojobId={workerIdToShow} onClose={this.handleInfoClose} /> : null}
      </div>
    );
  }
}

export default hot(module)(MissionList);
