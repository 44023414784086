import { AmendmentDto } from '../../server/common/dto/amendment.dto';
import { ChildAmendmentDto } from '../../server/common/dto/child-amendment.dto';

export function isMissionLinked(mission: { id?: string; amendment?: AmendmentDto; amendments?: ChildAmendmentDto[] }): boolean {
  if (mission.id && mission.id != "") {
    return true;
  }
  if (mission.amendment) {
    return isMissionLinked(mission.amendment.root) || mission.amendment.root.amendments.some(isMissionLinked);
  }
  if (mission.amendments) {
    return mission.amendments.some(isMissionLinked);
  }
  return false;
}
