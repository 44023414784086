import React from 'react';
import { hot } from 'react-hot-loader';

import { ExtractScopedInvoiceDto } from '../../server/modules/extract/dto/extract-scoped-invoice.dto';
import { getInvoiceLink } from '../tools/admin';

interface Props {
  invoice: ExtractScopedInvoiceDto;
}

function formatInvoiceNumber(number: string): string {
  return (number || '').replace(/(\d{4})(\d{2})(\d{6})/, '$1-$2-$3');
}

const GojobInvoiceLink = ({ invoice }: Props) => (
  <a href={getInvoiceLink(invoice.gojobId)} target="_blank">
    Facture {formatInvoiceNumber(invoice.number)}
  </a>
);

export default hot(module)(GojobInvoiceLink);
