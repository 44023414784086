import { ValidationError } from 'class-validator';
import fetch from 'cross-fetch';

import { getToken, logout } from './authentication';

const api = (process.env.SERVER_URL || '') + '/api';

interface URLParameters {
  [key: string]: any;
}

export interface IValidationErrorObject {
  [property: string]: string;
}

export interface ApiError {
  message?: string;
  validationErrors?: IValidationErrorObject;
}

function encodeURIParameters(parameters: URLParameters): string {
  return Object.keys(parameters)
    .filter(key => key !== undefined)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(parameters[key]))
    .join('&');
}

export async function get<T>(endpoint: string): Promise<T | undefined>;
export async function get<T, U extends URLParameters>(endpoint: string, parameters: U): Promise<T | undefined>;
export async function get<T, U extends URLParameters>(endpoint: string, parameters?: U): Promise<T | undefined> {
  const queryParams = parameters ? encodeURIParameters(parameters) : '';
  return tryFetch<T>(`${api}${endpoint}${queryParams ? `?${queryParams}` : ''}`);
}

export async function sendDelete<T>(endpoint: string): Promise<T | undefined> {
  return tryFetch<T>(`${api}${endpoint}`, { method: 'DELETE' });
}

export async function post<T, U>(endpoint: string, payload: U): Promise<T | undefined> {
  return send<T, U>('POST', endpoint, payload);
}

export function put<T, U>(endpoint: string, payload: U): Promise<T | undefined> {
  return send<T, U>('PUT', endpoint, payload);
}

/**
 * Wrap fetch in a try ... catch, adding headers
 * @param url
 */
async function tryFetch<T>(url: string, init?: RequestInit): Promise<T | undefined> {
  try {
    const res = await fetch(url, { headers: getHeaders(), ...init });
    if (res.status >= 200 && res.status < 300) {
      return res.json();
    }
    if (res.status === 403) {
      logout();
    }
  } catch (e) {}
  return undefined;
}

/**
 * Consume GOJOB Admin API
 * @param method
 * @param endpoint
 * @param payload
 * @throws {ApiError}
 * @returns Promise<T>
 */
async function send<T, U>(method: 'POST' | 'PUT', endpoint: string, payload: U): Promise<T> {
  let res: Response;
  let error: ApiError;
  try {
    res = await fetch(`${api}${endpoint}`, {
      method,
      body: JSON.stringify(payload),
      headers: getHeaders({ 'Content-Type': 'application/json' }),
    });
    if (res.status >= 200 && res.status < 300) {
      return res.json();
    }
  } catch (err) {
    error = err;
  }

  if (res.status === 400) {
    const response = await res.json();
    if (response && (response.validationErrors || (response.message && response.message.validationErrors))) {
      error = {
        message: 'Validation des données',
        validationErrors: buildValidationErrorObject(response.validationErrors || response.message.validationErrors),
      };
    }
  }
  if (!error) {
    error = { message: res.statusText || 'Unknown error' };
  }
  throw error;
}

function getHeaders(headers: any = {}) {
  return { Authorization: `Bearer ${getToken()}`, ...headers };
}

function buildValidationErrorObject(errors: ValidationError[], path: string = ''): IValidationErrorObject {
  let errorObject: IValidationErrorObject = {};
  errors.forEach(error => {
    if (error.children.length) {
      errorObject = { ...errorObject, ...buildValidationErrorObject(error.children, path + error.property + '.') };
    }
    if (error.constraints) {
      const constraint = Object.keys(error.constraints).shift();
      errorObject[path + error.property] = error.constraints[constraint];
    }
  });
  return errorObject;
}
