import React from 'react';
import { hot } from 'react-hot-loader';

import { CompanyDto } from '../../server/common/dto/company.dto';
import { MissionDto } from '../../server/common/dto/mission.dto';
import { CompanyConfigurationDto } from '../../server/modules/company/dto/company-configuration.dto';
import { getCompany, getConfiguration } from '../tools/api';
import Loading from './Loading';
import MissionList from './MissionList';

interface Props {
  match: {
    params: {
      id: string;
    };
  };
}

interface State {
  loading: boolean;
  company: CompanyDto;
  configuration: CompanyConfigurationDto;
  missionToLink: MissionDto;
}

class CompanyLinked extends React.Component<Props, State> {
  state: State = {
    loading: true,
    company: undefined,
    configuration: undefined,
    missionToLink: undefined,
  };

  async componentDidMount() {
    const company = await getCompany(this.props.match.params.id);
    const configuration = await getConfiguration(this.props.match.params.id);
    this.setState({ loading: false, company, configuration });
  }

  handleLinkRequest = async (mission: MissionDto) => {
    this.setState({ missionToLink: mission });
  };

  renderElectronicSignature() {
    return (
      <div style={{ color: 'red', marginBottom: 20 }}>
        Les <strong>contrats de mise à disposition</strong> de ce client doivent être déposés sur la plateforme <strong>MyPixid</strong> afin qu'il
        puisse les signer électroniquement
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    }
    return (
      <div>
        <h1>{this.state.company.name}</h1>
        {this.state.configuration.requireElectronicSignature ? this.renderElectronicSignature() : null}
        <MissionList companyId={this.state.company.id} onLinkRequest={this.handleLinkRequest} />
      </div>
    );
  }
}

export default hot(module)(CompanyLinked);
