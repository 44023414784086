import React from 'react';
import * as ReactDOM from 'react-dom';

import '../../node_modules/antd/dist/antd.min.css';
import Layout from './components/PageLayout';
import './style.css';

import './config-moment';

ReactDOM.render(<Layout />, document.getElementById('root') as HTMLElement);
