import { Button, Icon } from 'antd';
import React from 'react';
import { hot } from 'react-hot-loader';

import { ExtractDto } from '../../server/modules/extract/dto/extract.dto';

interface Props {
  extract: ExtractDto;
}

function fileSizeSI(size: number): string {
  const e = (Math.log(size) / Math.log(1e3)) | 0;
  return +(size / Math.pow(1e3, e)).toFixed(2) + ' ' + ('kMGTPEZY'[e - 1] || '') + 'B';
}

const Extract = ({ extract }: Props) => (
  <Button className="download-extract-btn" href={`${extract.url}`} target="_blank">
    <Icon type="download" theme="outlined" />
    <span>{extract.filename}</span>
    <span>{fileSizeSI(extract.size)}</span>
  </Button>
);

export default hot(module)(Extract);
