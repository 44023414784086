const adminURL = process.env.ADMIN_URL;

export function getCompanyURL(gojobId: string): string {
  return `${adminURL}/company/details/${gojobId}`;
}

export function getMissionLink(gojobId: string): string {
  return `${adminURL}/missions/details/${gojobId}/summary`;
}

export function getUserLink(gojobId: string): string {
  return `${adminURL}/user/${gojobId}/`;
}

export function getInvoiceLink(gojobId: string): string {
  return `${adminURL}/invoices/details/${gojobId}`;
}
