import { CompanyDto } from '../../server/common/dto/company.dto';
import { GenericResultDto } from '../../server/common/dto/generic-result.dto';
import { MissionLightDto } from '../../server/common/dto/mission-light.dto';
import { MissionDto } from '../../server/common/dto/mission.dto';
import { StructureLightDto } from '../../server/common/dto/structure-light.dto';
import { UpdateResultDto } from '../../server/common/dto/update-result.dto';
import { WorkerDto } from '../../server/common/dto/worker.dto';
import { IExchangeFieldOption } from '../../server/common/interfaces/exchange-field-option.interface';
import { IExchangeField } from '../../server/common/interfaces/exchange-field.interface';
import { CompanyConfigurationDto } from '../../server/modules/company/dto/company-configuration.dto';
import { LinkCompanyDto } from '../../server/modules/company/dto/link-company.dto';
import { LinkCompanyParamsDto } from '../../server/modules/company/dto/link-company.params.dto';
import { SearchCompanyParamsDto } from '../../server/modules/company/dto/search-company.params.dto';
import { SearchedCompanyDto } from '../../server/modules/company/dto/searched-company.dto';
import { ExtractListDto } from '../../server/modules/extract/dto/extract-list.dto';
import { ExtractDto } from '../../server/modules/extract/dto/extract.dto';
import { RemainingDataDto } from '../../server/modules/extract/dto/remaining-data.dto';
import { UpdateExtractParamsDto } from '../../server/modules/extract/dto/update-extract.params.dto';
import { MissionLinkParamsDto } from '../../server/modules/mission/dto/mission-link.params.dto';
import { MissionListParamsDto } from '../../server/modules/mission/dto/mission-list.params.dto';
import { ApiError, get, post, put, sendDelete } from './ajax';

export async function findCompany(name: string): Promise<SearchedCompanyDto[]> {
  if (name) {
    return get<SearchedCompanyDto[], SearchCompanyParamsDto>(`/companies/find`, { search: name });
  }
  return [];
}

export async function linkCompany(companyId: string, params: LinkCompanyParamsDto): Promise<{ company?: CompanyDto; error?: ApiError }> {
  try {
    const { company } = await put<LinkCompanyDto, LinkCompanyParamsDto>(`/companies/${companyId}/link`, params);
    return { company, error: company ? null : { message: 'Unknown error' } };
  } catch (error) {
    return { error };
  }
}

export function getCompanyList(): Promise<CompanyDto[]> {
  return get<CompanyDto[]>('/companies');
}

export function getCompany(id: string): Promise<CompanyDto | undefined> {
  return get<CompanyDto>(`/companies/${id}`);
}

export async function refreshMissions(): Promise<{ updated?: boolean; error?: Error }> {
  try {
    const { updated } = await get<UpdateResultDto>(`/missions/refresh`);
    return { updated };
  } catch (error) {
    return { error };
  }
}

export async function refreshMission(gojobId: string): Promise<{ updated?: boolean; error?: Error }> {
  try {
    const { updated } = await get<UpdateResultDto>(`/missions/refresh/${gojobId}`);
    return { updated };
  } catch (error) {
    return { error };
  }
}

export async function refreshInvoices(): Promise<{ updated?: boolean; error?: Error }> {
  try {
    const { updated } = await get<UpdateResultDto>(`/invoices/refresh`);
    return { updated };
  } catch (error) {
    return { error };
  }
}

export function deleteExtract(id: string): Promise<ExtractDto> {
  return sendDelete<ExtractDto>(`/files/${id}`);
}

export function getWorker(gojobId: string): Promise<WorkerDto | undefined> {
  return get<WorkerDto, { gojobId: string }>(`/workers`, { gojobId });
}

export function refreshWorker(gojobId: string): Promise<WorkerDto | undefined> {
  return get<WorkerDto, { gojobId: string }>(`/workers/refresh`, { gojobId });
}

export function getMissions(companyId: string): Promise<MissionLightDto[]> {
  return get<MissionLightDto[], MissionListParamsDto>(`/missions`, { companyId });
}

export function getUnlinkedMission(gojobId: string): Promise<MissionDto | undefined> {
  return get<MissionDto>(`/missions/unlinked/${gojobId}`);
}

export function getConfiguration(companyId: string): Promise<CompanyConfigurationDto> {
  return get<CompanyConfigurationDto>(`/companies/${companyId}/configuration`);
}

export function getStructures(companyId: string): Promise<StructureLightDto[]> {
  return get<StructureLightDto[]>(`/companies/${companyId}/structures`);
}

export function getExchangeFields(companyId: string): Promise<IExchangeField[]> {
  return get<IExchangeField[]>(`/companies/${companyId}/exchange`);
}

export async function getCustomJobs(companyId: string): Promise<IExchangeFieldOption[]> {
  const jobs = await get<IExchangeFieldOption[]>(`/companies/${companyId}/jobs`);
  return jobs || [];
}

export async function linkMission(params: MissionLinkParamsDto): Promise<{ mission?: MissionLightDto; error?: ApiError }> {
  try {
    const mission = await post<MissionLightDto, MissionLinkParamsDto>('/missions', params);
    return { mission, error: mission ? null : { message: 'Unknown error' } };
  } catch (error) {
    return { error };
  }
}

export async function unLinkMission(missionId: string): Promise<{ mission?: MissionLightDto; error?: ApiError }> {
  try {
    const mission = await put<MissionLightDto,{}>(`/missions/unlink/${missionId}`, {});
    return { mission, error: mission ? null : { message: 'Unknown error' } };
  } catch (error) {
    return { error };
  }
}

export async function tagExtractAsSent(extractId: string): Promise<{ extract?: ExtractDto; error?: ApiError }> {
  try {
    const extract = await put<ExtractDto, UpdateExtractParamsDto>(`/files/${extractId}`, { sent: true });
    return { extract, error: extract ? null : { message: 'Unknown error' } };
  } catch (error) {
    return { error };
  }
}

export function hasRemainingData(): Promise<RemainingDataDto> {
  return get<RemainingDataDto>(`/files/remains`);
}

export function generateNewExtract(): Promise<ExtractListDto> {
  return get<ExtractListDto>(`/files/generate`);
}

export function getLatestExtracts(): Promise<ExtractListDto> {
  return get<ExtractListDto>(`/files/`);
}

export async function invalidateMission(gojobId: string): Promise<boolean> {
  try {
    const { success } = await put<GenericResultDto, { gojobId: string }>(`/missions/invalidate`, { gojobId });
    return success;
  } catch (err) {
    return false;
  }
}
