import { Form, Modal, notification } from 'antd';
import React from 'react';
import { hot } from 'react-hot-loader';

import { CompanyDto } from '../../server/common/dto/company.dto';
import { SearchedCompanyDto } from '../../server/modules/company/dto/searched-company.dto';
import { linkCompany } from '../tools/api';
import CompanySearchBar from './CompanySearchBar';

interface Props {
  company: CompanyDto;
  onClose: (company?: CompanyDto) => void;
}

interface State {
  company: SearchedCompanyDto;
  confirmLoading: boolean;
}

class CompanyLinkForm extends React.Component<Props, State> {
  state: State = {
    company: null,
    confirmLoading: false,
  };

  handleSelectCompany = (company: SearchedCompanyDto) => {
    this.setState({ company });
  };

  handleOk = async () => {
    const { company, error } = await linkCompany(this.props.company.id, { gojobId: this.state.company.gojobId });
    if (!error) {
      notification.success({
        message: 'Client Lié',
        description: 'Le client a bien été lié',
      });
      this.props.onClose(company);
    } else {
      notification.error({
        message: 'Erreur lors de la mise à jour',
        description: error.message || 'Erreur inconnue',
      });
    }
  };

  handleCancel = () => {
    this.props.onClose();
  };

  render() {
    return (
      <Modal
        visible={true}
        title={this.props.company.name}
        confirmLoading={this.state.confirmLoading}
        okButtonProps={{ disabled: !this.state.company }}
        cancelButtonProps={{ disabled: this.state.confirmLoading }}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
      >
        <Form>
          <CompanySearchBar onSelect={this.handleSelectCompany} />
        </Form>
      </Modal>
    );
  }
}

export default hot(module)(CompanyLinkForm);
