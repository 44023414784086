import { Spin } from 'antd';
import React from 'react';
import { hot } from 'react-hot-loader';

const Loading = () => (
  <div className="LoadingBox">
    <Spin />
  </div>
);

export default hot(module)(Loading);
