import { IExchangeFieldOption } from './exchange-field-option.interface';

export interface IExchangeFieldSelect {
  key: string;
  label: string;
  values: IExchangeFieldOption[];
}

export interface IExchangeFieldText {
  key: string;
  label: string;
  type: 'string';
}

export type IExchangeField = IExchangeFieldSelect | IExchangeFieldText;

export function isExchangeFieldSelect(field: any): field is IExchangeFieldSelect {
  return field.values;
}

export function isExchangeFieldText(field: any): field is IExchangeFieldText {
  return field.type === 'string';
}
