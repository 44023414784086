import { Button, Icon, notification, Tooltip } from 'antd';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader';

import { refreshMission } from '../tools/api';

const RefreshMissionButton = ({ gojobId, disabled }: { gojobId: string; disabled: boolean }) => {
  const [loading, setLoading] = useState(false);

  async function refresh() {
    setLoading(true);
    const {updated, error} = await refreshMission(gojobId)

    if (!error) {
      notification.success({
        message: 'Mise à jour',
        description: 'Cette mission a bien été mise à jour',
      });
    } else {
      notification.error({
        message: 'Erreur lors de la mise à jour',
        description: 'Erreur inconnue',
      });
    }

    setLoading(false);
  }

  return (
    <Tooltip placement="bottomRight" title="Rafraichir la mission">
      <Button key="refresh" type="default" disabled={disabled} onClick={refresh}>
        {loading ? null : <Icon type="reload" />}
      </Button>
    </Tooltip>
  );
};

export default hot(module)(RefreshMissionButton);
