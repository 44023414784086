import React from 'react';
import { hot } from 'react-hot-loader';

import { ExtractLogAuthorDto } from '../../server/modules/extract/dto/extract-log-author.dto';
import { getUserLink } from '../tools/admin';

interface Props {
  user: ExtractLogAuthorDto;
}

const GojobUserLink = ({ user }: Props) => (
  <a href={getUserLink(user.gojobId)} target="_blank">
    {user.firstname} {user.lastname}
  </a>
);

export default hot(module)(GojobUserLink);
