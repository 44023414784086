import { Button, Icon, notification, Tooltip } from 'antd';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader';

import { invalidateMission } from '../tools/api';

const InvalidateCompanyButton = ({ gojobId, disabled }: { gojobId: string; disabled: boolean }) => {
  const [loading, setLoading] = useState<boolean>(false);

  async function invalidate() {
    setLoading(true);
    const success = await invalidateMission(gojobId);

    if (success) {
      notification.success({
        message: 'Mise à jour',
        description: 'Cette mission sera bien exportée dans le prochain export',
      });
    } else {
      notification.error({
        message: 'Erreur lors de la mise à jour',
        description: 'Erreur inconnue',
      });
    }
    setLoading(false);
  }

  return (
    <Tooltip placement="bottomRight" title="Forcer l'envoi dans le prochain export">
      <Button key="invalidate" type="default" disabled={disabled} loading={loading} onClick={invalidate}>
        {loading ? null : <Icon type="cloud-upload" />}
      </Button>
    </Tooltip>
  );
};

export default hot(module)(InvalidateCompanyButton);
