import moment from 'moment';

import { MissionLightDto } from '../../server/common/dto/mission-light.dto';
import { MissionDto } from '../../server/common/dto/mission.dto';

export function decodeContractNumber(mission: MissionDto | MissionLightDto): string {
  if (!mission.number) {
    return '-';
  }
  return mission.number
    .split('-')
    .map(base36 => parseInt(base36, 36))
    .join('-');
}

export function formatDate(date: string) {
  return moment(date).format('DD-MM-YYYY');
}

export function formatReadableDate(date: string) {
  return moment(date).format('dddd DD MMMM YYYY');
}
