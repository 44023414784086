import { Icon, message } from 'antd';
import Button from 'antd/lib/button/button';
import Form from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import Input from 'antd/lib/input/Input';
import React from 'react';
import { hot } from 'react-hot-loader';

import { login } from '../tools/authentication';

interface Props {
  onChange: () => void;
}

interface State {
  login: string;
  password: string;
}

class Login extends React.Component<Props, State> {
  state: State = {
    login: '',
    password: '',
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    this.setState({
      [event.target.name]: event.target.value as string,
    });
  };

  handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const result = await login(this.state.login, this.state.password);
    if (result) {
      this.props.onChange();
    } else {
      message.error('Login ou mot de passe incorrect');
    }
  };

  render = () => {
    return (
      <div style={{ textAlign: 'center' }}>
        <h1>Clic & Staff</h1>
        <br />
        <p>
          Entrez vos identifiants v1 <a href="https://admin.gojob.com">admin.gojob.com</a> :
        </p>
        <Form onSubmit={this.handleSubmit} style={{ maxWidth: 300, display: 'inline-block' }}>
          <FormItem>
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              name="login"
              placeholder="Username"
              onChange={this.handleChange}
            />
          </FormItem>
          <FormItem>
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              name="password"
              placeholder="Password"
              onChange={this.handleChange}
            />
          </FormItem>
          <FormItem>
            <Button type="primary" htmlType="submit" className="login-form-button">
              Log in
            </Button>
          </FormItem>
        </Form>
      </div>
    );
  };
}

export default hot(module)(Login);
