import { Badge, Button, Collapse, Icon } from 'antd';
import React from 'react';
import { hot } from 'react-hot-loader';
import { Link } from 'react-router-dom';

import assetCompanyLinkPopup from './Help-Assets/company-link-popup.png';
import assetCompanyLink from './Help-Assets/company-link.png';
import assetCompanyLinked from './Help-Assets/company-linked.png';
import assetElectronicSignature from './Help-Assets/electronic-signature-label.png';
import assetExtractGenerated from './Help-Assets/extract-generated.png';
import assetMissionNotLinked from './Help-Assets/mission-not-linked.png';
import assetSentBySFTP from './Help-Assets/sent-by-ftp.png';

const Panel = Collapse.Panel;

const customPanelStyle = {
  background: '#f7f7f7',
  borderRadius: 4,
  marginBottom: 10,
  overflow: 'hidden',
};

const imageStyle = {
  display: 'block',
  margin: '10px auto',
};

const importantStyle = {
  color: 'red',
};

const HelpSummary = () => (
  <Collapse style={{ background: 'transparent', border: 0 }}>
    <Panel header="Présentation générale" key="prez_gal" style={customPanelStyle}>
      <p>
        Clic & Staff permet à certain de nos clients de gérer de façon centralisée leurs interimaires avec differents prestataires d'interim comme
        Gojob. <br />
        <br />
        Pour cela, il nous faut envoyer nos contracts et factures dans le système de Clic & Staff. <br />
        Les informations nécessaires pour Clic & Staff sont propres à chaque client (Dispeo, Legrand...) et peuvent être assez différentes entres les
        clients. <br />
        <br />
        Ce site permet d'envoyer les informations de la base Gojob au système de Clic & Staff afin qu'elles soient visible sur{' '}
        <a href="https://www.clicandstaff.fr/login" target="_blank">
          l'interface de Clic & Staff
        </a>{' '}
        par le client. <br />
      </p>
    </Panel>

    <Panel header="Ajout d'un client" key="new_client" style={customPanelStyle}>
      <p>L'ajout d'un client ne peut se faire que par un développeur qui doit intégrer le cahier des charges fourni par Clic & Staff</p>
    </Panel>

    <Panel header="Lier un client" key="link_client" style={customPanelStyle}>
      <p>
        Une fois le cahier des charges du client intégré par un développeur, le nouveau client apparaîtra dans la{' '}
        <Link to="/companies"> liste des clients</Link> . <br />
        Il faut alors le lier à l'entreprise sur Gojob, pour cela, cliquer sur le bouton <Button type="primary">Lier</Button> qui lui est associé :
        <br />
        <img src={assetCompanyLink} style={imageStyle} />
        <br />
        <br />
        Un popup apparaîtra, vous permettant de recherche sur Gojob l'entreprise correspondante.
        <img src={assetCompanyLinkPopup} style={imageStyle} />
        <br />
        <br />
        Lorsque qu'un client est lié à une entreprise sur Gojob, un lien vers l'entreprise sur gojob ainsi que son listing des missions est disponible
        dans la <Link to="/companies"> liste des clients</Link>.
        <img src={assetCompanyLinked} style={imageStyle} />
      </p>
    </Panel>

    <Panel header="Lier une mission" key="link_mission" style={customPanelStyle}>
      <p>
        Pour chaque mission, il est nécessaire de fournir un certain lot d'informations supplémentaires qui est spécifique au client. <br />
        <br />
        Pour cela, il suffit d'aller sur le listing des missions et de cliquer sur le bouton <Button type="primary">Lier</Button> de la nouvelle
        mission :
        <img src={assetMissionNotLinked} style={imageStyle} />
        Un formulaire va apparaître pour ajouter ces informations qui sont à obtenir chez le client lors de la commande. <br />
        <br />
        <strong style={importantStyle}>Une fois la mission liée, il est important de générer un nouvel export et de l'envoyer à Clic & Staff</strong>
      </p>
    </Panel>

    <Panel header="Signature Electronique" key="electronic_signature" style={customPanelStyle}>
      <p>
        Certains clients souscrivent à l’offre PSI pour signer électroniquement les contrats via Clic&Staff. <br />
        L’offre PSI utilisant la technologie myPixid, les contrats du client doivent être déposés sur cette plateforme, afin qu’il puisse les signer.{' '}
        <br />
        <br />
        <strong>
          Lorsqu'un client à souscrit à cette offre, le cahier des charges fournit pour l'intégrer contient les informations nécessaires à la
          signature électronique
        </strong>{' '}
        - en page 5 généralement.
        <br />
        <br />
        Le message suivant sera visible en dessous du nom du client dans le listing des missions:
        <br />
        <img src={assetElectronicSignature} style={imageStyle} />
      </p>
    </Panel>

    <Panel header="Export des données" key="extract" style={customPanelStyle}>
      <p>
        Exporter les données afin de les envoyer à Clic & Staff est une étape <strong>importante et nécessaire</strong> après : <br />
      </p>
      <ul>
        <li>Avoir lié une ou plusieurs missions</li>
        <li>Lorsqu'une mission a été mise à jour sur Gojob (fin de mission, avenant...)</li>
        <li>Lors de l'émission de facture sur Gojob</li>
      </ul>
      <p>
        Pour cela, il suffit de se rendre sur la partie <Link to="/files">Extracts</Link>
        <br />
        <br />
        Lorsque de nouvelles factures ont été émises sur Gojob, il faut au préalable cliquer sur le bouton{' '}
        <Button type="primary" className="btn-volcano">
          Rafraichir les factures
        </Button>
        <br />
        <br />
        Lorsque les données des missions ont changées sur Gojob, il faut au préalable cliquer sur le bouton{' '}
        <Button type="primary" className="btn-green">
          Rafraichir les missions
        </Button>
        <br />
        <br />
        Il suffit ensuite de cliquer sur le bouton <Button type="primary">Générer un nouvel export</Button>
        <br />
        <br />
        <em>
          Tips: Lorsque des données sont dans l'attente d'un export, le bouton sera actif par défaut, et une icone va apparaître sur son coin droit{' '}
        </em>
        <Badge count={<Icon type="fire" theme="twoTone" twoToneColor="#ff0000" style={{ fontSize: 18 }} />}>
          <Button type="primary">Générer un nouvel export</Button>
        </Badge>
        <br />
        <br />
        Cette opération va générer soit un soit deux fichiers qui doivent être envoyé à Clic & Staff.
        <br />
        <br />
        Si le SFTP fonctionne correctement, ces fichiers seront envoyés automatiquement à la fin de la procédure et un log l'indiquant apparaitra
        alors.
        <br />
        <img src={assetSentBySFTP} style={imageStyle} />
        <br />
        Dans le cas contraire, il faut les envoyer à Clic & Staff par email{' '}
        <strong style={{ color: 'red' }}>en conservant leur nom tel qu'il apparait sur le bouton de download</strong> <br />
        <em>
          => Attention donc au renommage possible lors du download si un fichier du même nom existe déjà (en général, le système ajoute un (1) pour
          donner GOJ101 (1).zip
        </em>
        <br />
        <img src={assetExtractGenerated} style={imageStyle} />
        <br />
        <br />
        Télécharger le(s) fichier(s) en cliquant sur le bouton{' '}
        <Button className="download-extract-btn">
          <Icon type="download" theme="outlined" />
          <span>GOJ101.zip</span>
          <span>3.29 kB</span>
        </Button>
        <br />
        <br />
        Une fois envoyé par email à{' '}
        <a href="mailto:tma.clicandstaff@ajilon.fr?subject=export" target="_blank">
          tma.clicandstaff@ajilon.fr
        </a>{' '}
        (et uniquement après), cliquer sur le bouton <Button type="primary">Marquer comme envoyé</Button>
      </p>
    </Panel>

    <Panel header="Debug: Suppression d'un extract" key="rm_extract" style={customPanelStyle}>
      <p>
        <strong style={{ color: 'red' }}>Il s'agit d'une opération de maintenance réservé à un développeur. </strong>
        <br />
        <br />
        C'est une opération qui permet au développeur de supprimer un extract afin d'en générer un nouveau lors de la correction d'un bug.
      </p>
    </Panel>
    <Panel header="Debug: Forcer la mise à jour d'une mission" key="force_refresh" style={customPanelStyle}>
      <p>
        Renvoyer un jeu de donnée plusieurs fois chez Clic & Staff ne pose aucun problème. <br />
        <br />
        Lorsque le rapport d'intégration des extracts mais en évidence un problème de données ou de format (suite à la mise à jour du cahier des
        charges par exemples), il peut-être nécessaire de forcer le renvoi d'une mission (et des données associées). <br />
        Pour cela, il suffit de cliquer sur le bouton pour forcer son export dans le listing mission{' '}
        <Button key="invalidate" type="default">
          <Icon type="cloud-upload" />
        </Button>
        <br />
        Si le problème concerne les données du gojobbeur, il faut au prélable aller lancer la mise à jour de ses données, en cliquant sur{' '}
        <Button key="info" type="default">
          <Icon type="user" />
        </Button>{' '}
        puis sur <Button key="back">Rafraîchir les données de l'utilisateur</Button>
        <br />
        <br />
        Enfin, il convient évidemment de générer un nouvel export.
      </p>
    </Panel>
  </Collapse>
);

export default hot(module)(HelpSummary);
